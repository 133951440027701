import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactNode[];
  gap?: string;
  minWidth?: number;
}

export default function Masonry({
  children,
  gap = '10px',
  minWidth = 300,
}: Props) {
  const cols: ReactNode[][] = [];
  const ref = useRef<HTMLDivElement>();
  const [numCols, setNumCols] = useState(3);

  const calcNumCols = () => {
    if (ref.current) {
      setNumCols(Math.max(Math.floor(ref.current.offsetWidth / minWidth), 1));
    }
  };

  const createCols = () => {
    for (let i = 0; i < numCols; i++) cols[i] = [];
    children.forEach((child, i) => cols[i % numCols].push(child));
  };

  useEffect(() => {
    calcNumCols();
    window.addEventListener(`resize`, calcNumCols);
    return () => window.removeEventListener(`resize`, calcNumCols);
  });
  createCols();

  return (
    <MasonryDiv ref={ref} gap={gap}>
      {Array(numCols)
        .fill(1)
        .map((el, i) => (
          <Col key={i} gap={gap}>
            {cols[i]}
          </Col>
        ))}
    </MasonryDiv>
  );
}

const MasonryDiv = styled.div<{ gap: string }>`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${(props) => props.gap};
`;

const Col = styled.div<{ gap: string }>`
  display: grid;
  grid-gap: ${(props) => props.gap};
  grid-auto-rows: max-content;
`;
