import { Link, graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import Layout from '../components/Layout';
import Masonry from '../components/Masonry';
import React from 'react';
import SEO from '../components/SEO';
import styled from 'styled-components';

interface Props {
  data: {
    trip: {
      date: string;
      images: {
        path: {
          childImageSharp: {
            fluid: FluidObject;
          };
        };
        slug: string;
        title: string;
      }[];
      slug: string;
      title: string;
    };
  };
}

export default function Trip({ data: { trip } }: Props) {
  return (
    <Layout subtitle={`${trip.title}, ${trip.date}`}>
      <SEO title={`${trip.title}, ${trip.date}`} />
      <Wrapper>
        <Masonry>
          {trip.images.map((image) => (
            <ImageLink
              key={image.slug}
              to={`/travel/${trip.slug}/${image.slug}`}
              title={image.title}
            >
              <Img alt={image.title} fluid={image.path.childImageSharp.fluid} />
            </ImageLink>
          ))}
        </Masonry>
      </Wrapper>
    </Layout>
  );
}

export const query = graphql`
  query TripTemplate($slug: String!) {
    trip: tripsYaml(slug: { eq: $slug }) {
      date
      images {
        path {
          childImageSharp {
            fluid(quality: 50, maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        slug
        title
      }
      slug
      title
    }
  }
`;

const Wrapper = styled.div`
  padding: 10px;
`;

const ImageLink = styled(Link)`
  position: relative;

  &::after {
    background: #fff;
    content: ' ';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.15s ease-in;
    width: 100%;
  }

  &:active,
  &:hover {
    box-shadow: none;

    &::after {
      opacity: 0.2;
    }
  }
`;
